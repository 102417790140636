import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { isProdOrStaging } from 'utils/util';
import { useAppLogout } from 'utils/appLogout';
import { useSignInIfNeeded } from 'hooks/common/useSignInIfNeeded';
import { NewListWizardPage } from 'routes/app/lists/wizard/new';
import NotFoundView from './flat/NotFoundView';
// import { Questions } from './newprospectslists/Questions';
import { EditListWizardPage } from 'routes/app/lists/wizard/edit';
import { ViewListPage } from 'routes/app/lists/[uuid]/view';

// Only lazy load routes component.
const AdminLayout = lazy(() => import('routes/layouts/AdminWrapper'));
const AuthLayout = lazy(() => import('routes/layouts/AuthWrapper'));
const Planner = lazy(() => import('./Planner'));
const Settings = lazy(() => import('./settings/Settings'));
const ProspectList = lazy(() => import('./ProspectList'));
const ProspectLists = lazy(() => import('./ProspectLists'));
const Signup = lazy(() => import('./auth/signup'));
const Setup = lazy(() => import('./auth/setup'));
const Home = lazy(() => import('./app/home/Home'));
const Register = lazy(() => import('./auth/Register'));
const VerifyEmail = lazy(() => import('./VerifyEmail'));
const AcceptTeamInvitationImmediately = lazy(
  () => import('./settings/AcceptTeamInvitationImmediately')
);

const EmailVerified = lazy(() => import('./settings/EmailVerified'));
const SsoLogin = lazy(() => import('./SsoLogin'));

const pathParser = () => {
  const path = window.location.pathname
    .replace(new RegExp('^/'), '')
    .replace(new RegExp('/$'), '');
  const path_array = path.split('/');
  const crtl = path_array[0];
  const ext = path_array[1];

  let context = null;

  if (crtl === 'auth') {
    context = '404';

    if ('handle_login' === ext) {
      context = null;
    }
  }
  return context;
};

const withContextParser = (Component: React.ComponentType<any>) => {
  const meta = document.querySelector('meta[name="status"]') as HTMLMetaElement;
  let context = meta ? meta.content : null;
  context = context || pathParser();
  let returned_component;
  switch (context) {
    case '404': {
      returned_component = (props: any) => (
        <AdminLayout {...props} context="404" />
      );
      break;
    }
    default: {
      returned_component = (props: any) => <Component {...props} />;
      break;
    }
  }
  return returned_component;
};

export function Routes() {
  const logout = useAppLogout();

  const signInIfNeeded = useSignInIfNeeded();
  return (
    <Switch>
      <Route
        path="/pricing"
        exact
        component={() => {
          window.location.href = `${window.location.protocol}//${window.location.hostname}/pricing`;
          return null;
        }}
      />
      <Route path={'/app/verify-email'} component={VerifyEmail} />
      <Route component={Planner} path="/app/planner" exact />
      <Route component={Planner} path="/app/planner/:team_uuid" exact />
      <Route path="/app/myprospects" exact>
        <Redirect to="/app/myprospects/lists" />
      </Route>
      <Route
        component={ProspectLists}
        path={[
          '/app/myprospects/lists',
          '/app/myprospects/people',
          '/app/myprospects/organizations',
        ]}
        exact
      />
      <Route component={ProspectList} path="/app/prospectlist/:uuid" exact />
      <Route
        path="/app/myprospects/lists/:listUuid/edit"
        component={EditListWizardPage}
        exact
      />
      <Route
        path="/app/myprospects/lists/new"
        component={NewListWizardPage}
        exact
      />
      <Route
        path="/app/myprospects/lists/:listUuid"
        component={ViewListPage}
        exact
      />
      <Route
        path="/app/myprospects/lists/:listUuid/edit/:questionId"
        component={EditListWizardPage}
        exact
      />
      <Route
        path="/app/myprospects/lists/new/:questionId"
        component={NewListWizardPage}
        exact
      />

      {/** @ts-expect-error TS(2769): No overload matches this call. */}
      <Route component={Setup} path="/auth/setup" exact onBoarding noFooter />
      <Route path={['/app/feed', '/app/home']} component={Home} exact />

      <Route component={Settings} path="/app/settings" exact />
      <Route
        path="/app/team/:teamUuid/invitation/:uuid/accept"
        component={AcceptTeamInvitationImmediately}
      />

      <Route path="/auth/email-verified" component={EmailVerified} />

      <Route path="/auth/signin" render={signInIfNeeded} />

      <Route
        path="/auth/logout"
        render={() => {
          logout();
          return <></>;
        }}
      />
      <Route path="/auth/signup" component={Signup} exact />
      <Route path="/auth/sso-login" component={SsoLogin} exact />
      <Route path="/app" render={withContextParser(AdminLayout)} />

      <Route path="/auth/register" component={Register} exact />
      <Route path="/auth" render={withContextParser(AuthLayout)} />
      <Route path="/404" render={NotFoundView} exact />
      {!isProdOrStaging() && (
        <>
          <Redirect from="/" to="/app/home" exact />
          <Redirect from="/frontend" to="/app/home" exact />
        </>
      )}
    </Switch>
  );
}
