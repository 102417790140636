import { Stack, Typography, Grid, Box } from '@mui/material';
import { FilterBox } from '../../ProspectList/ICPBuilder/Filters/FilterBox';
import {
  WizardAnswer,
  WizardConfig,
  WizardMultipleChoiceQuestion,
} from '../WizardConfig';
import { WizardPagination } from '../WizardPagination';
import { useHistory } from 'react-router';
import { Wizard } from '../Wizard';

export interface MultipleChoiceQuestionProps<TWizard = Wizard<WizardConfig>> {
  wizard: TWizard;
  question: WizardMultipleChoiceQuestion;
  onAnswerClick: (answer: WizardAnswer) => void;
}

export function MultipleChoiceQuestion({
  wizard,
  question,
  onAnswerClick,
}: MultipleChoiceQuestionProps) {
  const history = useHistory();

  function onPreviousClick() {
    history.goBack();
  }

  // the label and description can be a function that takes the wizard as an argument
  const questionLabel =
    typeof question.label === 'function'
      ? question.label(wizard)
      : question.label;
  const description =
    typeof question.description === 'function'
      ? question.description(wizard)
      : question.description;

  return (
    <Stack spacing={8}>
      <Stack
        flexWrap="wrap"
        alignItems="flex-start"
        key={question.id}
        spacing={2}
      >
        <Typography fontWeight={500} variant="h6">
          {questionLabel}
        </Typography>
        {question.description ? (
          <Typography variant="body2">{description}</Typography>
        ) : null}
        <Box ml={-2} width="100%">
          <Grid container columnSpacing={2} rowSpacing={2}>
            {question.answers?.map((answer, index) => {
              // TODO: remove this functionality, and handle within the config object.
              if (wizard.shouldHideIfQuestionAnswered(answer)) return null;
              return (
                <Grid item md={6} xs={6} flexGrow={1} key={index}>
                  <FilterBox
                    title={answer.label}
                    onClick={() => onAnswerClick(answer)}
                    activated={false}
                    icon={answer.icon}
                    slotProps={{
                      button: {
                        sx: {
                          width: '100%',
                          minHeight: 100,
                          height: '100%',
                          justifyContent: 'flex-start',
                          alignItems: 'none',
                        },
                      },
                    }}
                    description={answer.description}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Stack>
      <WizardPagination
        wizard={wizard}
        question={question}
        canGoForward={false}
        onPreviousClick={onPreviousClick}
      />
    </Stack>
  );
}
