import { Box, IconProps, Tooltip } from '@mui/material';
import { ListEntityType } from 'adapters/types';
import { MuiIconManifest } from 'utils/iconManifest';

export function ListEntityTypeIcon({
  value,
  slotProps = {},
}: {
  value: ListEntityType;
  slotProps?: {
    icon?: {
      sx?: IconProps['sx'];
    };
  };
}) {
  const sx = {
    width: '1rem',
    height: '1rem',
    ...(slotProps?.icon?.sx ? slotProps?.icon?.sx : {}),
  };
  switch (value) {
    case ListEntityType.Profiles:
      return (
        <Tooltip title="This list is made up of individuals.">
          <MuiIconManifest.PeopleOutlineIcon
            {...(slotProps?.icon ? slotProps?.icon : {})}
            sx={sx}
          />
        </Tooltip>
      );

    case ListEntityType.Organizations:
      return (
        <Tooltip title="This list is made up of organizations.">
          <MuiIconManifest.CorporateFareOutlinedIcon sx={sx} />
        </Tooltip>
      );

    default:
      return null;
  }
}

export function ListEntityTypeCell({ value }: { value: ListEntityType }) {
  const icon = <ListEntityTypeIcon value={value} />;
  if (!icon) return null;

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
      }}
    >
      {icon}
    </Box>
  );
}
