import { ListType } from 'adapters/types';
import {
  WizardAnswer,
  WizardConfig,
  AnyWizardQuestion,
  WizardStorage,
} from './WizardConfig';
import type {
  Option,
  WizardListParams,
  WizardMode,
  WizardResponse,
  WizardVersion,
} from './WizardConfig';
import {
  processComplexInputUpload,
  processSimpleInputUpload,
} from './WizardFormDataProcessors';
import { clearbitIndustrySubCategoryMap } from 'variables/icp-data';

export const STORAGE_PREFIX = 'vdx:wiz';

/**
 * @file Business logic and helpers for multi-step wizards.
 */

export class Wizard<TConfig extends WizardConfig = WizardConfig> {
  config: TConfig;
  version: WizardVersion;
  mode: WizardMode = 'create';

  get localStorageKey() {
    return `${STORAGE_PREFIX}:${this.config.id}:${this.config.version}`;
  }

  get basePath() {
    return this.config.basePath;
  }

  // Simulate a chainable API (wizard.questions.etc())
  questions = {
    byId: this.getQuestionById.bind(this),
    urlFor: this.getUrlForQuestionId.bind(this),
    valueForMCAnswer: this.getValueForMultipleChoiceAnswer.bind(this),
    isFinal: this.questionIsFinal.bind(this),
  };

  // wizard.storage.etc()
  storage = {
    clear: this.clearStorage.bind(this),
    fetch: this.fetchStorage.bind(this),
    fetchResponse: this.fetchResponseForQuestion.bind(this),
    saveResponse: this.saveResponseForQuestion.bind(this),
    update: this.updateStorage.bind(this),
    getFormValuesMap: this.getFormPayload.bind(this),
    responseHasChanged: this.responseHasChangedForMCQuestion.bind(this),
    clearSubsequentResponses: this.clearSubsequentResponses.bind(this),
  };

  constructor(config: TConfig) {
    this.config = config;
    this.version = config.version;
  }

  get description(): string | undefined {
    return this.config.description;
  }

  getQuestionById(id: AnyWizardQuestion['id']): AnyWizardQuestion | undefined {
    const q = this.config.questions.find((q) => q.id === id);

    if (!q) {
      console.error(`Question with id ${id} not found.`);
      const storageState = this.fetchStorage();

      // remove this specific question from the responses
      storageState.responses = storageState.responses.filter(
        (r) => r.questionId !== id
      );
      this.storage.update(storageState);
      return;
    }

    return q;
  }

  getUrlForQuestionId(id: AnyWizardQuestion['id']): string {
    return `${this.basePath}/${id}`;
  }

  /**
   * Save a response for a question.
   *
   * @param questionId
   * @param value
   */
  saveResponseForQuestion(questionId: AnyWizardQuestion['id'], value: any) {
    const question = this.getQuestionById(questionId);
    if (!question) return; // skip if question is not found

    const responseHasChanged = this.storage.responseHasChanged(
      questionId,
      value
    );

    const storageState = this.fetchStorage();

    // Clear saved responses for the given question and any questions that follow it.
    //  Only applies to multiple choice questions, since only they affect the path of following steps.
    if (responseHasChanged && question.type === 'multiple-choice') {
      const index = storageState.responses.findIndex(
        (r) => r.questionId === questionId
      );
      storageState.responses = storageState.responses.slice(0, index);
    }

    const index = storageState.responses.findIndex(
      (r) => r.questionId === questionId
    );

    const newValue = {
      questionId,
      value,
    };

    //
    // if a response to this question exists, replace it in the same position.
    //
    if (index !== -1) {
      storageState.responses[index] = newValue;
    } else {
      //
      // Otherwise add it at the end.
      //
      storageState.responses.push(newValue);
    }

    this.storage.update(storageState);
  }

  /**
   * Retrieve desearialized storage state.
   *
   * @returns {WizardStorage}
   */
  fetchStorage(): WizardStorage {
    let storageString = localStorage.getItem(this.localStorageKey);
    let storageState;

    if (storageString) {
      storageState = JSON.parse(storageString) as WizardStorage;
    } else {
      storageState = {
        wizardId: this.config.id,
        wizardVersion: this.config.version,
        responses: [],
      };
    }

    return storageState;
  }

  /**
   * Replace storage state with a new one.
   *
   * @param storageState
   */
  updateStorage(storageState: WizardStorage) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(storageState));
  }

  clearStorage() {
    localStorage.removeItem(this.localStorageKey);
  }

  /**
   * Generate value for localStirage from the label.
   * NOTE: this will change!
   *
   * @param question
   * @param answer
   * @returns
   */
  getValueForMultipleChoiceAnswer(
    question: AnyWizardQuestion,
    answer: WizardAnswer
  ) {
    return answer.value;
  }

  /**
   * Retrieve a question's response from localStorage, if it exists.
   *
   * @param questionId
   */
  fetchResponseForQuestion(questionId: AnyWizardQuestion['id']) {
    const storageState = this.fetchStorage();

    return storageState.responses.find((r) => r.questionId === questionId);
  }

  /**
   * Map sub industries to clearbit map .
   *
   */
  getClearbitSubcategory(clearbitIndustry: string): string {
    return clearbitIndustrySubCategoryMap[clearbitIndustry] || 'Other';
  }

  /**
   * Retrieve all entered values from localstorage.
   * Return a multi-dimentional structure, defined by dotted 'name' attributes.
   *
   * TODO: this can be made more generic to be reusable across wizards.
   */
  getFormPayload(): WizardListParams {
    const storageSnapshot = this.fetchStorage();
    const responses = storageSnapshot.responses;
    const formValues: any = {};

    responses.forEach(({ questionId, value }) => {
      const question = this.getQuestionById(questionId);
      if (!question) return; // skip if question is not found

      // Questions without names are wizard-only; they don't get sent to the API.
      if (!question.name) {
        return;
      }

      // Check if it's a CRM list
      if (question.name === 'is_crm_list' && value === 'CRM') {
        formValues.is_crm = true;
        return; // don't bother with the rest of the question
      }

      // Get the appropriate value for the question type.
      if (
        question.type === 'input-upload' &&
        Array.isArray(value) &&
        value[0]?.value
      ) {
        // Strip out empty values.
        const baseOptions = value.filter((v: any) => !!v.value);

        if (
          question?.subType &&
          ['people', 'organizations'].includes(question?.subType)
        ) {
          formValues[question.name] = processComplexInputUpload(
            question,
            baseOptions
          );
        } else {
          // Other autocomplete values will just be arrays.
          formValues[question.name] = processSimpleInputUpload(baseOptions);
        }
      } else if (
        question.type === 'tree-select-autocomplete' &&
        Array.isArray(value)
      ) {
        const baseOptions = value.filter((v: any) => !!v.value);

        formValues[question.name] = baseOptions.map((o: Option) => {
          return this.getClearbitSubcategory(o.label);
        });
      } else {
        // String values.
        formValues[question.name] = value;
      }
    });

    // list_source is set by the question config.
    formValues.result_type = formValues.list_entity_type = this.getResultType(
      responses,
      formValues
    );

    formValues['wizard_state'] = storageSnapshot;

    return formValues;
  }

  /**
   * Determine whether this is  a people or organization list based on responses to key questions (list type, lead type).
   * @param responses
   * @returns
   */

  getResultType(responses: WizardResponse[], formValues: WizardListParams) {
    // There should never be more than one response that matches name === list_source.
    const responseQuestions = responses
      .map(({ questionId }) => this.questions.byId(questionId))
      .filter(Boolean)
      .filter(
        // Non-null assertion is ok here since we filter out falsy values above.
        (q) => q!.name === 'list_source'
      );

    if (formValues.list_entity_type) {
      return formValues.list_entity_type;
    }

    const responseValue = responses?.find(
      (r) => r.questionId === responseQuestions[0]?.id
    )?.value;

    if (['People'].includes(responseValue as string)) {
      return 'profiles';
    }

    if (formValues.list_type === ListType.Competitors) {
      return 'organizations';
    }

    return 'organizations';
  }

  /**
   * Gets the text for the "Previous" button based on the current question ID.
   * This method will later be moved to a wizard-specific class to tailor button text per wizard type.
   * @param {string} currentQuestionId - The ID of the current question being displayed.
   * @returns {string} The text to display on the "Previous" button.
   */
  getPreviousButtonText(currentQuestionId: AnyWizardQuestion['id']): string {
    const index = this.config.questions.findIndex(
      (q) => q.id === currentQuestionId
    );

    return index === 0 ? 'Cancel' : 'Previous';
  }

  /**
   * Gets the text for the "Next" button based on the current question ID.
   * This method will later be moved to a wizard-specific class to tailor button text per wizard type.
   * @param {string} currentQuestionId - The ID of the current question being displayed.
   * @returns {string} The text to display on the "Next" button.
   */
  getNextButtonText(currentQuestionId: AnyWizardQuestion['id']): string {
    const index = this.config.questions.findIndex(
      (q) => q.id === currentQuestionId
    );

    // TODO: This is specific to the list wizard. extract to a list-wizard specific class.

    if (currentQuestionId === 'list-name') {
      return this.mode === 'edit' ? 'Save list' : 'Create list';
    }

    // Determine if this is the last question
    if (index === this.config.questions.length - 1) {
      return 'Finish';
    } else {
      return 'Next';
    }
  }

  /**
   * Clear saved responses for the given question and any questions that follow it.
   * Used when we change our answer for a multiple choice question, so that we don't
   *  end up with a response history that intersperses responses from different paths.
   *
   * @param questionId The starting question to clear.
   */
  clearSubsequentResponses(questionId: AnyWizardQuestion['id']) {
    const storageState = this.fetchStorage();

    const questionIndex = this.config.questions.findIndex(
      (q) => q.id === questionId
    );

    if (questionIndex === -1) {
      return;
    }

    storageState.responses = storageState.responses.slice(0, questionIndex);

    this.storage.update(storageState);
  }

  responseHasChangedForMCQuestion(
    questionId: AnyWizardQuestion['id'],
    newValue: any
  ): boolean {
    const existingResponse = this.storage.fetchResponse(questionId);

    return !!existingResponse?.value && existingResponse?.value !== newValue;
  }

  questionIsFinal(question: AnyWizardQuestion): boolean {
    return question.isFinal === true;
  }

  shouldBootstrap(): boolean {
    return false;
  }

  /**
   * Return true if the answer has a hideIfQuestionAnswered property, and any of its entries made an existing question/response pair.
   */
  shouldHideIfQuestionAnswered(answer: WizardAnswer): boolean {
    const storageState = this.fetchStorage();

    if (!answer.hideIfQuestionAnswered) return false;

    for (const { questionId, answerValue } of answer.hideIfQuestionAnswered) {
      const response = storageState.responses.find(
        (r) => r.questionId === questionId
      );

      if (response?.value === answerValue) {
        return true;
      }
    }

    return false;
  }
}
