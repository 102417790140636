import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ScrollToTop from 'components/common/Widgets/ScrollToTop';

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'setupAxios.ts';
import AppAuth0Provider from './AppAuth0Provider';
import { store } from './redux/store';
import { loadLocale } from './utils/date';
import { theme } from './utils/theme';
import { PostHogProvider } from './PostHogProvider';

export function AppProvider({ children }: any) {
  // get the first part of the browser language setting, e.g., 'en' from 'en-US'
  useEffect(() => {
    loadLocale(navigator.language.split('-')[0]);
  }, []);

  // set the text for the apply button in date pickers to "Apply" rather than "OK" only if english is the language
  const customLocaleText = navigator.language.startsWith('en')
    ? {
        okButtonLabel: 'Apply',
      }
    : {};

  return (
    <Provider store={store}>
      <BrowserRouter>
        <PostHogProvider>
          <AppAuth0Provider>
            <ScrollToTop />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  adapterLocale={navigator.language.split('-')[0]}
                  dateAdapter={AdapterDayjs}
                  localeText={customLocaleText}
                >
                  {children}
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </AppAuth0Provider>
        </PostHogProvider>
      </BrowserRouter>
    </Provider>
  );
}
