import React from 'react';
import Box from '@mui/material/Box';
import MaterialNavbar from 'components/Navbars/MaterialNavbar';
import { FrontFooter } from 'routes/flat/FrontBlocks/FrontFooter';
import { css } from '@emotion/react';

const NavLogoStyle = css`
  span:before {
    display: inline-block;
    fontfamily: 'VDXLogo';
    fontstyle: normal;
    fontweight: normal;
    fontsize: 60px;
    color: var(--vdx-color);
    lineheight: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: 'A';
    marginleft: -4px;
  }
`;

export const ProtectedAdminLayout = (props: any) => {
  return (
    <>
      {props.onBoarding ? (
        <Box
          textAlign="center"
          sx={{ backgroundColor: 'var(--vdx-light-blue-color)' }}
          pt={4}
        >
          {/** @ts-expect-error TS(2322): Type 'SerializedStyles' is not assignable to type ... Remove this comment to see the full error message */}
          <span className={NavLogoStyle} />
        </Box>
      ) : (
        <MaterialNavbar />
      )}
      <Box className="main-content">{props.children}</Box>
      {!props.noFooter && <FrontFooter />}
    </>
  );
};
