import React from 'react';
import MuiLink from '@mui/material/Link';
import { useAuth0 } from '@auth0/auth0-react';

export interface LoginButtonProps {
  className?: string;
}

const LoginButton = ({ className, ...rest }: LoginButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <MuiLink
      href="#"
      underline="hover"
      className={className}
      onClick={() =>
        loginWithRedirect({
          appState: { returnTo: window.location.pathname },
          authorizationParams: {
            prompt: 'login',
          },
        })
      }
      {...rest}
    >
      Sign in
    </MuiLink>
  );
};

export default LoginButton;
