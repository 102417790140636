import React, { ComponentProps, PropsWithChildren, ReactElement } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { TabContext } from '@mui/lab';
import { UpperCaseTabList, UpperCaseTab } from './UpperCaseTabList';

interface SectionLayoutProps<T extends string | number> {
  title?: string | ReactElement;
  selectedTabValue?: T;
  tabs?: UpperCaseTab<T>[];
  onTabChange?: (event: React.SyntheticEvent, newValue: T) => void;
  rightsideToolbar?: React.ReactNode;
  slotProps?: {
    container: ComponentProps<typeof Container>;
    gridContainer?: ComponentProps<typeof Grid>;
    gridTitle?: ComponentProps<typeof Grid>;
    gridRightsideToolbar?: ComponentProps<typeof Grid>;
    // add more slot props as necessary
  };
}

/**
 * SectionLayout is a flexible layout component that can be used multiple times on a single page.
 * It supports optional tab navigation and allows for custom titles, toolbars, and slot props.
 *
 * Props:
 * - title: Optional title to display at the top, can be a string or a React element.
 * - selectedTabValue: Optional value to control the selected tab.
 * - tabs: Optional array of tab objects, each containing a value and a label.
 * - onTabChange: Optional callback function to handle tab changes.
 * - rightsideToolbar: Optional React node to display on the right side (usually for buttons).
 * - slotProps: Optional props to customize the Container component.
 * - children: Optional children to render within the layout, typically TabPanel components.
 */
export const SectionLayout = <T extends string | number>({
  title,
  selectedTabValue,
  tabs,
  onTabChange,
  rightsideToolbar = null,
  children,
  slotProps,
}: PropsWithChildren<SectionLayoutProps<T>>) => {
  const tabsExist =
    typeof selectedTabValue !== 'undefined' && typeof tabs !== 'undefined';

  const ContainerLayout = () => (
    <Container
      maxWidth="xl" // can be overwritten by slotProps.container.maxWidth
      sx={{
        mt: 4,
        mb: 4,
      }}
      {...slotProps?.container}
    >
      <Grid
        justifyContent="space-between"
        container
        alignItems="center"
        spacing={2}
        {...slotProps?.gridContainer}
      >
        <Grid item {...slotProps?.gridTitle}>
          {typeof title === 'string' ? (
            <Typography variant="h4" data-testid="section-layout-title">
              {title}
            </Typography>
          ) : (
            title
          )}
        </Grid>

        {/* optional right side toolbar */}
        {typeof rightsideToolbar !== 'undefined' && (
          <Grid item {...slotProps?.gridRightsideToolbar}>
            {rightsideToolbar}
          </Grid>
        )}
      </Grid>

      {tabsExist && <UpperCaseTabList tabs={tabs} onTabChange={onTabChange} />}

      {/* optional children */}
      {children && <Box mt={4}>{children}</Box>}
    </Container>
  );

  return tabsExist ? (
    <TabContext value={selectedTabValue.toString()}>
      <ContainerLayout />
    </TabContext>
  ) : (
    <ContainerLayout />
  );
};
