import {
  AnyWizardQuestion,
  ListWizardWizardParams,
  WizardAnswer,
  WizardConfig,
  WizardCRMIntegrationQuestion,
  WizardListParams,
  WizardListValueQuestion,
  WizardMultipleChoiceQuestion,
  WizardSelectInputQuestion,
} from '../../../../components/Wizard/WizardConfig';

/**
 * Specific list config type, derived from the base WizardConfig type.
 * The generic type for TQuestionType lets us enforce field names to directly map to the WizardListParams,
 *  to add some guardrails to our API call.
 */
export type ListWizardQuestion = AnyWizardQuestion &
  (
    | WizardMultipleChoiceQuestion
    | WizardListValueQuestion
    | WizardCRMIntegrationQuestion
    | WizardSelectInputQuestion
    | {
        name: keyof ListWizardWizardParams | keyof WizardListParams;
      }
  );
export type ListWizardConfig = WizardConfig<ListWizardQuestion> & {
  getCreateBasePath: () => string;
  getEditBasePath: (uuid: string) => string;
};

export function crmIntegrationCheckQuestion({
  id,
  nextQuestionId,
}: {
  id: ListWizardQuestion['id'];
  nextQuestionId: ListWizardQuestion['id'];
}): WizardCRMIntegrationQuestion {
  return {
    id,
    label: 'Checking for CRM integration...',
    type: 'crm-integration-check',
    nextQuestionId,
  };
}

export function organizationUploadQuestion({
  id,
  destinationQuestionId,
}: {
  id: ListWizardQuestion['id'];
  destinationQuestionId: ListWizardQuestion['id'];
}): ListWizardQuestion[] {
  return [
    {
      id,
      label: 'Upload a file or input the organizations for your list below.',
      description:
        'You may upload a CSV, XLS, or XLSX file. Domain name is a required field.',
      type: 'input-upload',
      name: 'included_organizations',
      subType: 'organizations',
      nextQuestionId: destinationQuestionId,
    },
  ];
}

export function peopleUploadQuestion({
  id,
  destinationQuestionId,
}: {
  id: ListWizardQuestion['id'];
  destinationQuestionId: ListWizardQuestion['id'];
}): ListWizardQuestion {
  return {
    id,
    label:
      'Upload a file or input the people you want to include in your list.',
    description:
      'You may upload a CSV, XLS, or XLSX file. Email is a required field.',
    type: 'input-upload',
    subType: 'people',
    name: 'included_people',
    nextQuestionId: destinationQuestionId,
  };
}

export function listEntityTypeQuestion({
  id,
  peopleNextQuestionId,
  organizationsNextQuestionId,
}: {
  id: ListWizardQuestion['id'];
  peopleNextQuestionId: ListWizardQuestion['id'];
  organizationsNextQuestionId: ListWizardQuestion['id'];
}): WizardMultipleChoiceQuestion {
  return {
    id,
    label: 'Who is on your list?',
    name: 'list_source',
    type: 'multiple-choice' as const,
    answers: [
      {
        label: 'People',
        description:
          'I am targeting specific individuals or personas (e/g job titles).',
        value: 'People',
        icon: 'PeopleOutlineIcon',
        nextQuestionId: peopleNextQuestionId,
      },
      {
        label: 'Organizations',
        description: 'I am targeting specific companies',
        value: 'Organizations',
        icon: 'CorporateFareOutlinedIcon',
        nextQuestionId: organizationsNextQuestionId,
      },
    ],
  };
}

export function peopleInputTypeAnswers({
  config,
}: {
  config: {
    type: 'upload' | 'describe' | 'CRM';
    nextQuestionId: ListWizardQuestion['id'];
  }[];
}) {
  return config.map(({ type: qType, nextQuestionId }) => {
    switch (qType) {
      case 'upload':
        return {
          label: 'Upload or input',
          description: 'Upload a file or input people directly.',
          value: 'upload',
          icon: 'PeopleOutlineIcon' as const,
          nextQuestionId,
        };
      case 'describe':
        return {
          label: 'Describe',
          description: 'Describe the people by job title and/or keywords.',
          value: 'describe',
          icon: 'EditAttributesOutlinedIcon' as const,
          nextQuestionId,
        };
      case 'CRM':
        return {
          label: 'CRM',
          description: 'Import from CRM',
          value: 'CRM',
          icon: 'EventNoteIcon' as const,
          nextQuestionId,
        };
      default: {
        throw new Error(`Unknown people input type: ${qType}`);
      }
    }
  });
}

export function peopleInputTypeQuestion({
  id,
  answers,
}: {
  id: ListWizardQuestion['id'];
  answers: WizardAnswer[];
}) {
  return {
    id,
    label: 'How would you like to select people for this list?',
    type: 'multiple-choice' as const,
    name: 'is_crm_list',
    answers,
  };
}

export function peoplePersonaFlow({
  endQuestionId,
  idPrefix,
}: {
  // nextQuestionId for any of the final (leaf) questions
  endQuestionId: ListWizardQuestion['id'];
  // Prefix for question IDs, to avoid conflicts with other flows
  idPrefix: string;
}): ListWizardQuestion[] {
  return [
    {
      id: `${idPrefix}-people-persona-flow`,
      label: 'Input or upload the job titles you would like to target.',
      description: 'You may upload a CSV, XLS, or XLSX file.',
      type: 'input-upload',
      subType: 'job-titles-keywords',
      name: 'included_job_titles',
      nextQuestionId: `${idPrefix}-people-job-titles-include-or-exclude`,
    },

    {
      id: `${idPrefix}-people-job-titles-include-or-exclude`,
      label:
        'Are there any job titles or keywords you want to exclude from your list?',
      description: 'Include job titles/keywords',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Exclude job titles',
          value: 'Exclude job titles',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: `${idPrefix}-people-job-titles-exclude`,
        },
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: endQuestionId,
        },
      ],
    },

    {
      id: `${idPrefix}-people-job-titles-exclude`,
      label: 'Input or upload the job titles you would like to exclude.',
      description: 'You may upload a CSV, XLS, or XLSX file.',
      type: 'input-upload',
      subType: 'job-titles-keywords',
      name: 'excluded_job_titles',
      nextQuestionId: endQuestionId,
    },
  ];
}

export function organizationInputTypeAnswers({
  config,
}: {
  config: {
    type: 'upload' | 'filter' | 'describe' | 'CRM';
    nextQuestionId: ListWizardQuestion['id'];
  }[];
}) {
  return config.map(({ type: qType, nextQuestionId }) => {
    switch (qType) {
      case 'upload':
        return {
          label: 'Upload or input',
          description: 'Upload a file or input organizations directly.',
          value: 'upload',
          icon: 'CorporateFareOutlinedIcon' as const,
          nextQuestionId,
        };

      case 'filter':
        return {
          label: 'Filter',
          description: 'Filter for organizations using an existing list.',
          value: 'filter',
          icon: 'FilterAltOutlinedIcon' as const,
          nextQuestionId,
        };

      case 'describe':
        return {
          label: 'Describe',
          description: 'Describe the organizations by industry and/or size.',
          value: 'describe',
          icon: 'EditAttributesOutlinedIcon' as const,
          nextQuestionId,
        };
      case 'CRM':
        return {
          label: 'CRM',
          description: 'Import organizations from your CRM.',
          value: 'CRM',
          icon: 'EventNoteIcon' as const,
          nextQuestionId,
        };
      default: {
        throw new Error(`Unknown organization input type: ${qType}`);
      }
    }
  });
}

export function organizationInputTypeQuestion({
  id,
  answers,
}: {
  id: ListWizardQuestion['id'];
  answers: WizardAnswer[];
}): WizardMultipleChoiceQuestion {
  return {
    id,
    label: 'How would you like to select companies for this list?',
    type: 'multiple-choice',
    name: 'is_crm_list',
    answers,
  };
}

export const listWizardConfig: ListWizardConfig = {
  id: 'list-wizard',
  version: 1.6,
  title: 'List builder',
  basePath: '/', // ignored
  getCreateBasePath: () => '/app/myprospects/lists/new',
  getEditBasePath: (uuid) => `/app/myprospects/lists/${uuid}/edit`,
  description:
    'Create a list of your prospects, CRM pipeline, customers, or competitors to gain insights into the events they are attending.',
  questions: [
    {
      id: 'list-type',
      label: 'What type of list would you like to build?',
      type: 'multiple-choice',
      name: 'list_type',
      answers: [
        {
          label: 'Prospects',
          value: 'Prospects',
          icon: 'HailIcon',
          description: 'Individuals or organizations in your target audience.',
          nextQuestionId: 'list-output-type',
        },
        {
          label: 'CRM Pipeline',
          value: 'Active pipeline',
          icon: 'BusinessCenterIcon',
          description: 'Individuals or organizations in an open deal.',
          nextQuestionId: 'pipeline-crm-integration-check',
        },
        {
          label: 'Customers',
          value: 'Customers',
          icon: 'HandshakeIcon',
          description: 'Organizations that are your clients or customers.',
          nextQuestionId: 'customer-list-output-type',
        },
        {
          label: 'Competitors',
          value: 'Competitors',
          icon: 'SportsKabaddiIcon',
          description: 'Organizations that are your competitors.',
          nextQuestionId: 'competitor-list-output-type',
        },
      ],
    },

    crmIntegrationCheckQuestion({
      id: 'pipeline-crm-integration-check',
      nextQuestionId: 'pipeline-list-output-type',
    }),

    listEntityTypeQuestion({
      id: `pipeline-list-output-type`,
      peopleNextQuestionId: 'crm-import-people',
      organizationsNextQuestionId: 'crm-import-organizations',
    }),

    listEntityTypeQuestion({
      id: `competitor-list-output-type`,
      peopleNextQuestionId: 'competitor-people-input-type',
      organizationsNextQuestionId: 'competitor-organizations-input-upload',
    }),

    listEntityTypeQuestion({
      id: `list-output-type`,
      peopleNextQuestionId: 'people-input-type',
      organizationsNextQuestionId: 'organization-input-type',
    }),

    listEntityTypeQuestion({
      id: `customer-list-output-type`,
      peopleNextQuestionId: 'customers-people-input-type',
      organizationsNextQuestionId: 'organization-input-type',
    }),

    // Company input question - select specific vs persona flow.

    organizationInputTypeQuestion({
      id: 'organization-input-type',
      answers: organizationInputTypeAnswers({
        config: [
          {
            type: 'upload',
            nextQuestionId: 'organizations-input-upload',
          },
          {
            type: 'describe',
            nextQuestionId: 'organization-attributes-industry',
          },
          {
            type: 'CRM',
            nextQuestionId: 'crm-import-organizations-check',
          },
        ],
      }),
    }),

    // Customer lists of people -> specific company flow.
    organizationInputTypeQuestion({
      id: 'customers-people-organization-input-type',
      answers: organizationInputTypeAnswers({
        config: [
          {
            type: 'upload',
            nextQuestionId: 'organizations-input-upload',
          },
        ],
      }),
    }),

    // People input question - select specific vs persona flow

    // Default: all people input options.
    peopleInputTypeQuestion({
      id: 'people-input-type',
      answers: peopleInputTypeAnswers({
        config: [
          {
            type: 'upload',
            nextQuestionId: 'people-input-upload',
          },
          {
            type: 'describe',
            nextQuestionId: 'default-people-persona-flow',
          },
          {
            type: 'CRM',
            nextQuestionId: 'crm-import-people-check',
          },
        ],
      }),
    }),

    // Competitors: no CRM for specific people, no company personas
    peopleInputTypeQuestion({
      id: 'competitor-people-input-type',
      answers: peopleInputTypeAnswers({
        config: [
          {
            type: 'upload',
            nextQuestionId: 'competitors-people-input-upload',
          },
          {
            type: 'describe',
            nextQuestionId: 'competitors-people-persona-flow',
          },
        ],
      }),
    }),

    // Customers:
    peopleInputTypeQuestion({
      id: 'customers-people-input-type',
      answers: peopleInputTypeAnswers({
        config: [
          {
            type: 'upload',
            nextQuestionId: 'people-input-upload',
          },
          {
            type: 'describe',
            nextQuestionId: 'customers-people-persona-flow',
          },
          {
            type: 'CRM',
            nextQuestionId: 'crm-import-people-check',
          },
        ],
      }),
    }),

    // #region Specific People flow.
    // TODO: put into function
    // Default case: for Leads, Pipeline, Customers.
    peopleUploadQuestion({
      id: 'people-input-upload',
      destinationQuestionId: 'value',
    }),

    peopleUploadQuestion({
      id: 'competitors-people-input-upload',
      destinationQuestionId: 'list-name',
    }),

    // #endregion

    // #region People Persona flow.

    // Default: end with all organization input options.
    ...peoplePersonaFlow({
      idPrefix: 'default',
      endQuestionId: 'organization-input-type',
    }),

    ...peoplePersonaFlow({
      idPrefix: 'competitors',
      endQuestionId: 'competitor-organizations-input-upload',
    }),

    ...peoplePersonaFlow({
      idPrefix: 'customers',
      endQuestionId: 'customers-people-organization-input-type',
    }),

    // #endregion

    // #region Specific Organizations flow.
    ...organizationUploadQuestion({
      id: 'organizations-input-upload',
      destinationQuestionId: 'value',
    }),

    ...organizationUploadQuestion({
      id: 'competitor-organizations-input-upload',
      destinationQuestionId: 'list-name',
    }),

    // #endregion

    // #region People CRM flow.

    crmIntegrationCheckQuestion({
      id: 'crm-import-people-check',
      nextQuestionId: 'crm-import-people',
    }),

    {
      id: 'crm-import-people',
      label:
        'Are there specific CRM contacts you want to include in your list?',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Include contact owner',
          value: 'Include contact owner',
          nextQuestionId: 'crm-import-people-include-input',
        },
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'value',
        },
      ],
    },

    {
      id: 'crm-import-people-include-input',
      label: 'Select the specific people you want to include in your list.',
      type: 'input-upload',
      name: 'included_contact_owners',
      subType: 'crm-owner',
      nextQuestionId: 'value',
    },

    // #endregion

    // #region Organizations CRM flow.

    crmIntegrationCheckQuestion({
      id: 'crm-import-organizations-check',
      nextQuestionId: 'crm-import-organizations',
    }),

    {
      id: 'crm-import-organizations',
      label: 'Do you want to choose a specific deal stage?',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Include deal stage',
          value: 'Include deal stage',
          nextQuestionId: 'deal-stage-include',
        },
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'deal-stage-exclude-or-skip',
        },
      ],
    },

    {
      id: 'deal-stage-include',
      label: 'Enter the deal stage to include.',
      type: 'input-upload',
      subType: 'crm-deal-stage',
      name: 'included_deal_stages',
      nextQuestionId: 'deal-stage-exclude-or-skip',
    },

    {
      id: 'deal-stage-exclude-or-skip',
      label: 'Do you want to exclude any specific deal stages?',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Exclude deal stage',
          value: 'Exclude deal stage',
          nextQuestionId: 'deal-stage-exclude',
        },
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'deal-owner-include-or-skip',
        },
      ],
    },

    {
      id: 'deal-stage-exclude',
      label: 'Enter the deal stage to exclude.',
      type: 'input-upload',
      subType: 'crm-deal-stage',
      name: 'included_deal_stages',
      nextQuestionId: 'deal-owner-include-or-skip',
    },

    {
      id: 'deal-owner-include-or-skip',
      label: 'Do you want to filter by specific deal owners?',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Include deal owner',
          value: 'Include deal owner',
          nextQuestionId: 'deal-owner-include',
        },
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'deal-owner-exclude-or-skip',
        },
      ],
    },
    {
      id: 'deal-owner-include',
      label: 'Enter the deal owner you want to include in your CRM pipeline.',
      type: 'input-upload',
      subType: 'crm-deal-owner',
      name: 'included_deal_owners',
      nextQuestionId: 'deal-owner-exclude-or-skip',
    },

    {
      id: 'deal-owner-exclude-or-skip',
      label: 'Do you want to exclude any deal owners?',
      type: 'multiple-choice',
      name: 'exclude_deal_owner_options',
      answers: [
        {
          label: 'Exclude deal owner',
          value: 'Exclude deal owner',
          nextQuestionId: 'deal-owner-exclude',
        },

        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'company-owner-include-or-skip',
        },
      ],
    },
    {
      id: 'deal-owner-exclude',
      label: 'Enter the deal owner you want to exclude in your CRM pipeline.',
      type: 'input-upload',
      subType: 'crm-deal-owner',
      name: 'excluded_deal_owners',
      nextQuestionId: 'company-owner-include-or-skip',
    },

    {
      id: 'company-owner-include-or-skip',
      label: 'Do you want to narrow down your pipeline by company owner?',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Include company owner',
          value: 'Include company owner',
          nextQuestionId: 'company-owner-include',
        },
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          // nextQuestionId: 'deal-created-date-or-skip',   // TODO no date related stuff yet for CRM
          nextQuestionId: 'value',
        },
      ],
    },

    {
      id: 'company-owner-include',
      label:
        'Which company owners do you want to include from your CRM import?',
      type: 'input-upload',
      subType: 'crm-owner',
      name: 'included_company_owners',
      nextQuestionId: 'value',
    },

    // #endregion

    // #region Organization Persona flow.

    {
      id: 'organization-attributes-industry',
      label: 'What industries are you targeting?',
      type: 'tree-select-autocomplete',
      name: 'included_industries',
      subType: 'sectors',
      nextQuestionId: 'organization-attributes-size',
      placeholder: 'Industries',
    },

    {
      id: 'organization-attributes-size',
      label: 'What size organizations are you targeting?',
      name: 'included_employee_ranges',
      type: 'input-upload',
      subType: 'organization-size',
      placeholder: 'Organization size',
      nextQuestionId: 'value',
    },
    // #endregion

    // #region crm filters

    // #endregion

    {
      id: 'value',
      type: 'list-value',
      name: 'average_deal_size',
      label: (wizard) => {
        const listType = wizard.getFormPayload().list_type;
        if (
          listType &&
          (listType === 'Customers' || listType === 'Active pipeline')
        ) {
          return 'Average value of customer';
        }
        return 'Average value of prospect';
      },
      description: (wizard) => {
        const listType = wizard.getFormPayload().list_type;
        return `By assigning a value to the ${
          listType &&
          (listType === 'Customers' || listType === 'Active pipeline')
            ? 'customers'
            : 'prospects'
        } on this list, you gain greater insight into the potential ROI from the events you care about.`;
      },
      nextQuestionId: 'list-name',
    },
    {
      id: 'list-name',
      name: 'list_name',
      label: 'What name would you like to give your list?',
      placeholder: 'Enter list name',
      type: 'text-input',
      isFinal: true,
    },
  ],
};
