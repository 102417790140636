import React from 'react';
import cns from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';

const style = makeStyles((theme) => ({
  signUpButton: {
    // @ts-expect-error TS(2339): Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    color: theme.palette.common.white,
    backgroundColor: '#39ba3a',
    padding: '6px 12px',
    fontWeight: 600,
    '&:hover': {
      // @ts-expect-error TS(2339): Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      color: theme.palette.common.white,
      backgroundColor: '#39ba3a',
    },
    border: 'none',
    verticalAlign: 'baseline',
    borderRadius: '50vh',
  },
  frontSignUpButton: {
    // @ts-expect-error TS(2339): Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    color: theme.palette.common.white,
    backgroundColor: '#39ba3a',
    padding: '17px 39px',
    fontWeight: 600,
    '&:hover': {
      // @ts-expect-error TS(2339): Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      color: theme.palette.common.white,
      backgroundColor: '#39ba3a',
    },
    border: 'none',
    verticalAlign: 'baseline',
    borderRadius: '8px',
  },
  btn: {
    textTransform: 'none',
  },
}));

const SignupButton = ({
  theme = {},
  defaultStyling = true,
  label = '',
  ...rest
}) => {
  // We want to use the popup, but for now it's easier to use the redirect
  // method with a new page. Fewer merge conflicts. Maybe.
  const { loginWithRedirect } = useAuth0();

  const classes = style();
  const buttonLabel = label || 'Sign up';
  return (
    <Button
      // @ts-expect-error TS(2339): Property 'signUpButton' does not exist on type '{}... Remove this comment to see the full error message
      className={cns(classes.btn, theme.signUpButton, {
        [classes.signUpButton]: defaultStyling,
      })}
      onClick={() =>
        loginWithRedirect({
          appState: { returnTo: window.location.pathname },
          authorizationParams: {
            prompt: 'login',
            screen_hint: 'signup',
          },
        })
      }
      {...rest}
    >
      {buttonLabel}
    </Button>
  );
};

export default SignupButton;
