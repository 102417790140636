import axios from 'axios';

const HEADERS = Object.freeze({
  json: { 'Content-Type': 'application/json' },
  form: { 'Content-Type': 'multipart/form-data' },
});

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.withCredentials = true;
axios.defaults.headers.get = HEADERS['json'];

/**
 * Force-logout the user if we get a 401 response from the server.
 */
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    if (error?.response?.status === 401) {
      console.info('Got HTTP 401, clearing local storage and redirecting to /');
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

if (window.location.hostname === 'localhost') {
  // @ts-expect-error TS(2339): Property '$$axios' does not exist on type 'Window ... Remove this comment to see the full error message
  window.$$axios = axios;
}

export { HEADERS };
