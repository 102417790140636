import { api } from './index';
import { strings } from '../../../variables/messages';
import { endpoints } from 'variables/endpoint-urls';
import { extractResults, reportResultInSnack } from './util';
import { ApiResponse } from 'utils/api-response';

const errorMessages = strings.user.error;

export interface GetUserStateProfile {
  image: string;
  name: string;
  uuid: string;
  url: string;
}

export interface GetUserStateTeam {
  uuid: string;
  name: string;
  credits: number;
  crm_connected: boolean;
}

export interface GetUserStateTeamInvitation {
  team_name: string;
  team_uuid: string;
  uuid: string;
  inviter_org: string | null;
}

interface GetUserStateResults {
  email: string;
  profile: GetUserStateProfile;
  useraccess: {
    subscription_status: SubscriptionKind;
  };
  team: GetUserStateTeam;
  team_invitation: GetUserStateTeamInvitation;
  impersonating: boolean;
}

export type GetUserStateResponse = ApiResponse<GetUserStateResults | null>;

export enum SubscriptionKind {
  NoSub = 'NoSub',
  ProfMonthly = 'ProfMonthly',
  ProfAnnual = 'ProfAnnual',
  PlusMonthly = 'PlusMonthly',
  PlusAnnual = 'PlusAnnual',
  PlusNewCredits = 'PlusNewCredits',
  Enterprise = 'Enterprise',
}

export interface UserCapabilitiesResults {
  capabilities: CapabilityKind[];
  is_staff: boolean;
}

export type CapabilityKind =
  | 'team:change_super_admin_permission'
  /**
   * Can promote users up to super admin and demote super admins and lower.
   */
  | 'team:super_admin_rank'
  /**
   * Can promote users up to admin and demote admins and lower.
   */
  | 'team:admin_rank'
  | 'team:edit_planner'
  | 'team:invite'
  | 'team:spend_credits'
  | 'team:set_preferences';

export interface GetUserCapabilitiesReturn {
  capabilities: Set<CapabilityKind>;
  isStaff: boolean;
}

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserState: builder.query<GetUserStateResponse, void>({
      query: () => ({
        url: endpoints.user.get.state,
        method: 'GET',
      }),
      providesTags: ['Credits', 'UserState'],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.state,
        });
      },
    }),

    //fetch all users capabilities, we need to move this into a higher HOC(preferably a userCapabilitiesHook)
    getUserCapabilities: builder.query<GetUserCapabilitiesReturn, void>({
      query: () => ({
        url: endpoints.user.get.capabilities,
        method: 'GET',
      }),
      transformResponse: (
        rawResponse: ApiResponse<UserCapabilitiesResults>
      ) => {
        const { capabilities, is_staff } = extractResults(rawResponse);
        return {
          capabilities: new Set(capabilities),
          isStaff: is_staff,
        };
      },
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          forceSuccess: true,
          defaultErrorMessage:
            "An error occurred fetching the current user's capabilities.",
        });
      },
    }),
    /**
     * Stop impersonating another user
     */
    clearImpersonation: builder.mutation<void, void>({
      query: () => ({
        url: endpoints.user.delete.impersonating,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserState'],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage:
            'An error occured while clearing the impersonation status.',
        });
      },
    }),
  }),
});
