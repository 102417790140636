import { Typography } from '@mui/material';

export function WizardFormError({ message }: { message: string }) {
  return (
    <Typography
      color="error"
      variant="caption"
      sx={{
        mt: '3px !important', // to make it look like the error messages from react-hook-form-mui
        ml: '14px !important', // to make it look like the error messages from react-hook-form-mui
      }}
    >
      {message}
    </Typography>
  );
}
