import { Box, Stack, Typography } from '@mui/material';
import { Wizard } from '../Wizard';
import { WizardConfig, WizardSelectInputQuestion } from '../WizardConfig';
import { WizardPagination } from '../WizardPagination';
import { WizardFormError } from '../WizardFormError';
import { SelectElement, useForm } from 'react-hook-form-mui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { optionsApi } from 'redux/reducers/api/options';
import { z } from 'zod';

export interface SelectInputQuestionProps<TWizard = Wizard<WizardConfig>> {
  question: WizardSelectInputQuestion;
  initialValue: string;
  wizard: TWizard;
  onSubmitForm: (value: string) => Promise<void>;
}

export function SelectInputQuestion({
  question,
  initialValue,
  wizard,
  onSubmitForm,
}: SelectInputQuestionProps) {
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const { data: prospectLists, isFetching: fetchingProspectLists } =
    optionsApi.useGetProspectListOptionsQuery();

  const defaultValues: Record<string, any> = useMemo(
    () => ({
      [question.name]: initialValue,
    }),
    [question.name, initialValue]
  );

  const schema = useMemo(
    () =>
      z.object({
        [question.name]: z.string().min(1, {
          message: 'Please provide a value.',
        }),
      }),
    [question.name]
  );

  type Schema = z.infer<typeof schema>;

  // Initialize form with default values from localStorage.
  const form = useForm<Schema>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const submitForm = useCallback(async () => {
    await onSubmitForm(form.getValues()[question.name]);
  }, [form, onSubmitForm, question.name]);

  const onNextClick = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  const onPreviousClick = useCallback(() => {
    history.goBack();
  }, [history]);

  let options: {
    // Type for parity with SelectElement['options']
    id: string | number;
    label: string | number;
    disabled?: boolean;
  }[] = [];
  let isFetching = false;

  switch (question.subType) {
    case 'prospect-lists':
      isFetching = fetchingProspectLists;
      options = (prospectLists || []).map((list) => ({
        label: list.label,
        id: list.uuid,
        disabled: false,
      }));
      break;
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Typography fontWeight={500} variant="h6">
          {question.label}
        </Typography>
        {question.description ? (
          <Typography variant="body2">{question.description}</Typography>
        ) : null}
        <form onSubmit={form.handleSubmit(submitForm)} ref={formRef}>
          {!isFetching && options?.length ? (
            <Box
              sx={{
                width: '100%',
              }}
            >
              <SelectElement
                name={question.name}
                label={question.placeholder || ''}
                control={form.control}
                options={options}
                sx={{
                  minWidth: {
                    xs: '200px',
                    md: '500px',
                  },
                }}
              />
            </Box>
          ) : null}

          {form.formState.errors[question.name] ? (
            <WizardFormError
              message={
                form.formState.errors[question.name]?.message ||
                'An unknown error occurred.'
              }
            />
          ) : null}
        </form>
      </Stack>
      <WizardPagination
        disabled={!form.formState.isValid}
        wizard={wizard}
        question={question}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
      />
    </Stack>
  );
}
