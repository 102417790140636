import { ListWizardWizardParams } from 'components/Wizard/WizardConfig';
import {
  ProspectListPermissions,
  TeamMembersWithPermissions,
} from 'modules/prospects/types';

export type User = {
  name: string;
  title: string;
  uuid: string;
  link: string;
  image: string;
  status: string;
  avatar?: string;
  organization: string;
};

export interface TeamMember extends User {
  prospects: number;
  slug: string;
}

// type for team members on the EDP page. faster loading than the full team member type
export interface EDPTeamMember {
  uuid: string;
  image: string;
  name: string;
  title: string;
}

// type for prospects on the EDP page. faster loading than the full prospect type
export interface EDPProspect {
  profile_uuid: string; // if attendee
  organization_uuid: string; // if sponsor
  owner_name: string;
  owner_uuid: string;
  list_name: string;
  list_uuid: string;
  list_type: ListType;
  list_entity_type: ListEntityType;
  is_shared: boolean; // if shared with you
}

export enum ListType {
  Customers = 'Customers',
  Competitors = 'Competitors',
  Prospects = 'Prospects',
  ActivePipeline = 'Active pipeline',
}

export enum ListEntityType {
  Profiles = 'profiles',
  Organizations = 'organizations',
}

export const ListTypes = Object.values(ListType);

export interface Prospect {
  owner_uuid: string;
  owner_name: string;
  owner_title: string;
  list_uuid: string;
  list_name: string;
  profile_uuid: string;
  name: string;
  title: string;
  profile_image: string;
  organization_uuid: string;
  organization: string;
  organization_image: string;
  status: string;
  profile_url: string;
  organization_url: string;
  email: string;
  city: string;
  region: string;
  country: string;
  profile_linkedin: string;
  profile_twitter: string;
  profile_crunchbase: string;
  org_linkedin: string;
  org_twitter: string;
  org_crunchbase: string;
}

// id parameter is needed for DataGrid
export interface ProspectWithId extends Prospect {
  id: string;
}

// stripped version of a prospect list only used on EDP
export interface EDPProspectList {
  uuid: string;
  name: string;
  orgs: string[];
  pprofiles: string[];
  isShared: boolean;
  list_type: ListType;
  list_entity_type: ListEntityType;
}

// type for returned prospect_list for algolia search prospect list filter
export interface ProspectListShort {
  crm_list: boolean;
  edp_matching_done_at: string;
  follow_org_members: boolean;
  has_icp_params: boolean;
  icp_params_updated_at: string;
  my_permissions: ProspectListPermissions[];
  name: string;
  orgs: {
    uuid: string;
    name: string;
  }[];
  profiles: {
    uuid: string;
    name: string;
  }[];
  smart_list: boolean;
  smart_list_last_operation: string;
  smart_list_status: string;
  smart_list_updated_at: string;
  uuid: string;
}

export interface ProspectListOwner {
  uuid: string;
  name: string;
  email: string;
  role: string;
  profile: string;
  avatar: string;
}

export interface ProspectList {
  link: string;
  uuid: string;
  list_type: ListType;
  name: string;
  owner: ProspectListOwner;
  my_permissions: ProspectListPermissions[];
  modified: string;
  created: string;
  examples: {
    name: string;
    abbreviation: string;
    image: string;
  }[];
  more_count: number;
  orgs: string[];
  pprofiles: string[];
  shared_with: TeamMembersWithPermissions[];
  org_count: number;
  profile_count: number;
  follow_org_members: boolean;
  smart_list?: boolean;
  smart_list_status?: string;
  wizard_params?: ListWizardWizardParams;
}

export type BaseResponseType = {
  success: boolean;
  message: string;
};

export interface Pic {
  full_name: string;
  name: string;
  url: string;
}

export interface Event {
  attendee_count: number;
  bimage: string;
  cost_high: string;
  cost_low: string;
  date_end: string;
  date_start: string;
  event_type: string;
  location: string;
  name: string;
  potential: string;
  slug: string;
  uuid: string;
  venue: string;
  prospects: Prospect[];
  pics: Pic[];
  search_pics: Pic[];
  member_pics: Pic[];
}

// new specific Prospect type for My Prospects page
export interface MyProspect {
  id: string;
  name: string;
  image: string;
  url: string;
  owners: ProspectOwner[];
  lists: MyProspectList[];
  profile_title?: string;
  profile_uuid?: string;
  organization_name: string;
  organization_uuid: string;
  organization_url?: string;

  // TODO: add these back in when we move to v2
  // events: number;
  // profile_email: string;
  // organization_domain: string;
}
// new Prospect Owner type for My Prospects page
export interface ProspectOwner {
  uuid: string;
  name: string;
  title: string;
  image: string;
  url?: string;
}

export type MyProspectList = {
  uuid: string;
  name: string;
  url: string;
  owner: ProspectOwner;
};

export type UPLOrg = {
  organization_uuid: string;
  organization_name: string;
  count: number; // total number of matches of a query
};
