// TODO: change breadcrumbs name to stepper
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
  Box,
  Stack,
  IconButton,
} from '@mui/material';
import { AnyWizardQuestion, WizardConfig } from './WizardConfig';
import { Wizard } from './Wizard';
import { capitalizeFirstChar, formatNumber } from 'utils/util';
import { MuiIconManifest } from 'utils/iconManifest';

interface WizardBreadcrumbsProps<TWizard = Wizard<WizardConfig>> {
  wizard: TWizard;
  currentQuestionId: AnyWizardQuestion['id'];
}

function getFormattedValue(questionId: AnyWizardQuestion['id'], value: any) {
  if (Array.isArray(value)) {
    return `${formatNumber(value.length)} added`;
  }

  if (questionId === 'list-output-type') {
    if (value === 'profiles') {
      return 'People';
    }
    if (value === 'organizations') {
      return 'Organizations';
    }
  }

  if (questionId === 'list-type') {
    if (value === 'Active pipeline') {
      return 'CRM pipeline';
    }
  }

  if (typeof value === 'number') {
    if (questionId === 'value') {
      return `$${formatNumber(value)}`;
    }

    return formatNumber(value);
  }
  if (typeof value === 'string') return capitalizeFirstChar(value);

  return value;
}

export function WizardBreadcrumbs({
  wizard,
  currentQuestionId,
}: WizardBreadcrumbsProps) {
  const { push } = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const storage = wizard.fetchStorage();

  useEffect(() => {
    if (storage && storage.responses) {
      const currentStepIndex = storage.responses.findIndex(
        (response) => response.questionId === currentQuestionId
      );

      if (currentStepIndex !== -1) {
        setActiveStep(currentStepIndex);
      } else {
        //  else, currentQuestionId is not in responses yet so handle what the next index should be if currentQuestionId were added
        setActiveStep(storage.responses.length);
      }
    }
  }, [currentQuestionId, storage]);

  const currentQuestion = wizard.questions.byId(currentQuestionId);
  if (!currentQuestion) return null;

  const currentQuestionLabel =
    typeof currentQuestion.label === 'function'
      ? currentQuestion.label(wizard)
      : currentQuestion.label;

  return (
    <Stepper
      sx={{
        maxHeight: 500,
        overflow: 'auto',
        '& .MuiStep-root:first-of-type .MuiStepLabel-root': {
          paddingTop: 0,
        },
      }}
      activeStep={activeStep}
      orientation="vertical"
    >
      {storage?.responses.map((response) => {
        const question = wizard.questions.byId(response.questionId);
        if (!question) return null;

        const value = response.value;

        const formattedValue = getFormattedValue(response.questionId, value);

        const questionLabel =
          typeof question.label === 'function'
            ? question.label(wizard)
            : question.label;

        return (
          <Step key={response.questionId}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              spacing={2}
            >
              <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
                <StepLabel>
                  <Box>{questionLabel}</Box>
                </StepLabel>
              </Stack>
              {/* don't let them edit a specific question if the wizard is outdated */}
              {!wizard.config.isOutdated ? (
                <IconButton
                  onClick={() => {
                    push(wizard.questions.urlFor(response.questionId));
                  }}
                >
                  <MuiIconManifest.EditIcon fontSize="small" />
                </IconButton>
              ) : null}
            </Stack>
            <StepContent TransitionProps={{ in: true }}>
              <Typography>{formattedValue}</Typography>
            </StepContent>
          </Step>
        );
      })}
      {/* render the current step if not yet in storage responses */}
      {storage &&
        !storage.responses.some(
          (response) => response.questionId === currentQuestionId
        ) && (
          <Step key={currentQuestionId}>
            <StepLabel>{currentQuestionLabel}</StepLabel>
          </Step>
        )}
    </Stepper>
  );
}
